<template>
    <div>
        <div class="row navbar-fixed-top">
            <div class="col-md-12">
                <div
                    class="alert alert-danger"
                    role="alert">
                    <p>
                        <i class="fa fa-warning"></i>
                        <strong>Let op:</strong>
                        &nbsp;&nbsp;Je werkt in Management Order {{ orderId }}

                        <button
                            type="button"
                            class="close"
                            data-dismiss="alert"
                            @click="forgetManagementOrder"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </p>
                </div>
            </div>
        </div>
        <button
            class="mao-button black mb-5"
            :disabled="storing"
            @click="storeManagementOrder">
            <template v-if="storing">
                <i class="fa fa-spin fa-spinner"></i>
                Opslaan...
            </template>

            <template v-else>
                <i class="fa fa-save"></i>
                Naar Management
            </template>
        </button>
    </div>
</template>

<script setup lang="ts">
    import {shoppingCartStore} from './Store.ts';
    import axios from 'axios';
    import {ref} from 'vue';

    let storing = ref(false);
    const store = shoppingCartStore();
    const props = defineProps({
        orderId: Number,
        code: String,
    });

    const forgetManagementOrder = () => {
        axios
            .delete(`/management/${props.code}/destroy`)
            .then(() => {
                window.location.href = window.location.origin + window.location.pathname;
            })
            .catch(() => {});
    };
    const storeManagementOrder = () => {
        storing.value = true;
        axios
            .put(`/management/${props.code}`, store.items)
            .then((response) => {
                storing.value = false;
                confirm('Gegevens succesvol opgeslagen, controleer de order.');
            })
            .catch(() => {
                storing.value = false;
                alert('Fout bij opslaan van order!');
            });
    };
</script>
