<template></template>

<script setup lang="ts">
    import {siteSettings as siteSettingsStore} from './Store.ts';
    import {onMounted} from 'vue';
    const siteSettings = siteSettingsStore();
    const props = defineProps(['sitesettings']);

    onMounted(() => {
        siteSettings.setSettings(props.sitesettings);
    });
</script>

<style scoped></style>
