<template>
    <div v-if="store.totalDeposit > 0">
        <div class="cart-summary-depositcosts">
            <div class="col-xs-6">
                {{ __('Statiegeld') }}
            </div>
            <div class="col-xs-6 mao-alignRight">
                &euro;
                <span id="cart-summary-total-depositcosts">{{ toFixedLocal(store.totalDeposit) }}</span>
            </div>
        </div>
    </div>

    <div class="cart-summary-item">
        <div class="col-xs-6">
            {{ __('Subtotaal') }}
        </div>
        <div class="col-xs-6 mao-alignRight">
            &euro;
            <span id="cart-summary-total-products">
                {{ toFixedLocal(store.subTotalPrice) }}
            </span>
        </div>
    </div>

    <div
        v-if="store.discountTotalPrice > 0"
        class="cart-summary-item cart-summary-item--discount">
        <div class="col-xs-6">
            {{ __('Korting actiecode') }}
        </div>
        <div class="col-xs-6 mao-alignRight">
            &euro;
            <span id="cart-summary-coupon-discount-total">{{ toFixedLocal(store.discountTotalPrice) }}</span>
        </div>
    </div>
    <div
        v-if="siteSettings.siteSettings.showShippingCostsInSummary"
        class="cart-summary-item">
        <div class="col-xs-6">
            {{ __('Bezorgkosten') }}
        </div>
        <div class="col-xs-6 mao-alignRight">
            <template v-if="store.deliveryTotalPrice > 0">
                &euro;
                <span id="cart-summary-deliverycosts">
                    {{ toFixedLocal(store.deliveryTotalPrice) }}
                </span>
            </template>
            <div
                v-else
                class="col-green">
                {{ __('Gratis') }}
            </div>
        </div>
    </div>

    <div class="cart-summary-item cart-summary-item--total">
        <div class="col-xs-4">
            {{ __('Totaal') }}
        </div>
        <div class="col-xs-8 mao-alignRight">
            &euro;
            <span id="cart-summary-total-price">{{ toFixedLocal(store.totalPrice) }}</span>
        </div>
    </div>

    <div
        :class="{'cart-table row': siteSettings.isChannel == 'cpd'}"
        style="padding-bottom: 20px">
        <div
            :class="
                siteSettings.isChannel == 'cpd' ? 'col-sm-8 col-sm-offset-4' : 'col-md-4 col-xs-12 col-lg-4 col-lg-offset-8 col-md-offset-8'
            "
            style="padding-top: 20px">
            <a
                :class="{inactiveLink: !store.requestDone}"
                :href="store.requestDone ? nextButtonAction : '#'">
                <button
                    :disabled="!store.requestDone"
                    class="mao-button yellow full-w">
                    {{ __(nextButtonTitle) }}
                </button>
            </a>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {shoppingCartStore, siteSettings as siteSettingsStore} from './Store';
    import {onMounted} from 'vue';

    const store = shoppingCartStore();
    const siteSettings = siteSettingsStore();
    const props = defineProps({
        nextButtonTitle: String,
        nextButtonAction: String,
    });
    onMounted(() => {
        store.axSetCart();
    });
</script>
<style scoped>
    .inactiveLink {
        pointer-events: none;
        cursor: default;
    }
</style>
