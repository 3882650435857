<template>
    <modal
        v-if="showModal"
        @close="showModal = false">
        <template #header>
            <i class="fa fa-check"></i>
            {{ __('Toegevoegd aan je winkelwagen') }}
        </template>
        <template #default>
            <div class="mao-attToCartItem">
                <div
                    class="mao-thumbImage mao-thumbImage--60x60 mao-thumbImage--bgWhite"
                    id="cart-add-image">
                    <img
                        :src="product.shoppingImage"
                        :alt="product.title"
                        class="list-image-shopping"
                        style="margin-top: -10px" />
                </div>

                <div
                    class="mao-productItemTitle mao-font600"
                    id="cart-add-title">
                    {{ product.title }}
                </div>

                <div class="mao-price-component mao-price-component--18px">
                    <span class="mao-price-component__main">
                        <span class="mao-price-component-euro">&euro;</span>
                        <span id="cart-add-price-new">{{ toFixedLocal(currentPrice) }}&nbsp;</span>
                    </span>
                    <span
                        v-if="oldPrice > currentPrice"
                        class="mao-price-component__secondary"
                        id="cart-add-price-old">
                        {{ toFixedLocal(oldPrice) }}
                    </span>
                </div>
            </div>
            <div class="row mao-continueOrProceed">
                <div class="col-sm-6 col-sm-push-6 mao-continueOrProceed__proceed">
                    <a :href="nextAction">
                        <button class="mao-button black buttonMobile">
                            {{ __('Bekijk je winkelwagen') }}
                            <i class="fa fa-angle-right"></i>
                        </button>
                    </a>
                </div>

                <div class="col-sm-6 col-sm-pull-6">
                    <span
                        @click="showModal = false"
                        href="#"
                        class="mao-blueLink"
                        data-dismiss="modal">
                        <i class="fa fa-angle-left"></i>
                        {{ __('Verder winkelen') }}
                    </span>
                </div>
            </div>

            <hr class="mao-hr" />
        </template>
    </modal>
</template>

<script setup lang="ts">
    import Modal from './Modal.vue';
    import {computed, ref} from 'vue';
    import {siteSettings as siteSettingsStore} from './Store.ts';

    let showModal = ref(false);
    let product = ref({});
    const nextAction = window.route.shoppingcart.cart;
    const siteSettings = siteSettingsStore();

    defineExpose({
        showModal,
        product,
    });

    const currentPrice = computed(() => product.value.netPrice * siteSettings.btw);
    const oldPrice = computed(() => product.value.grossPrice * siteSettings.btw);
</script>
