<template>
    <div class="row">
        <div
            v-if="store.deliveryDate"
            class="col-sm-6"
            id="shippingContainer">
            <i class="fa fa-truck col-green"></i>
            {{ __('De verwachte levertijd voor je bestelling is') }}
            <strong>{{ store.deliveryDate }}</strong>
        </div>
        <div class="col-sm-6">
            <cart-summary
                :next-button-title="nextButtonTitle"
                :next-button-action="nextButtonAction"></cart-summary>
            <ul class="green-checkList">
                <li>Vandaag besteld, morgen in huis</li>
                <li>14 dagen bedenktijd, op je gemak beoordelen</li>
                <li>Beste prijsgarantie, altijd de beste prijs</li>
            </ul>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {shoppingCartStore, siteSettings as siteSettingsStore} from './Store';
    import {onMounted} from 'vue';
    import CartSummary from './CartSummary.vue';

    const store = shoppingCartStore();
    const siteSettings = siteSettingsStore();
    const props = defineProps({
        nextButtonTitle: String,
        nextButtonAction: String,
    });
    onMounted(() => {
        store.axSetCart();
    });
</script>
<style scoped>
    .inactiveLink {
        pointer-events: none;
        cursor: default;
    }
</style>
