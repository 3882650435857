<script setup>
    import {shoppingCartStore} from './Store.ts';
    const store = shoppingCartStore();
</script>

<template>
    <div class="mao-header-cartandlogin__cart js__minicart-show">
        <i class="fa fa-shopping-cart"></i>
        <span>{{ store.total }}</span>
    </div>
</template>

<style scoped></style>
