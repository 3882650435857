import translations from '../../../../storage/app/translations.json';
import mapValues from 'lodash/mapValues';
import replace from 'lodash/replace';
import get from 'lodash/get';

export default {
    install: (app, options) => {
        app.config.globalProperties.__ = (key: string, replaceWords: Object = {}) => {
            let lang = options.lang || 'nl';
            let output = get(translations, `${lang}.${key}`) || key;

            return translateReplace(output, replaceWords);
        };
    },
};

// Duplicate method to global space to use in JS
if (typeof window !== undefined) {
    window.__ = (key: string, replaceWords: Object = {}) => {
        let lang = window.App.lang || 'nl';
        let output = translations[lang]?.[key] || key;

        return translateReplace(output, replaceWords);
    };
}

function translateReplace(string: string, replaceWords: Object) {
    if (Object.keys(replaceWords).length > 0) {
        debugger;
    }

    mapValues(replaceWords, (val: string, key: number) => {
        string = replace(string, `:${key}`, val);
    });

    return string;
}
