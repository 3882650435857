<template>
    <div>
        <select
            class="mao-select"
            :class="siteSettings.isChannel == 'cpd' ? 'mao-select--short' : 'mao-select__cart-order-quantity'"
            v-model="currentValue"
            @input="handleInput"
            @change="isMultiple">
            <option
                v-for="option in options"
                :key="option.value"
                :value="option.value">
                {{ option.text }}
            </option>
            <option>{{ __('meer') }}</option>
        </select>
        <modal
            :isBlue="true"
            v-if="showModal"
            @close="showModal = false">
            <template #header></template>

            <template #default>
                <div class="modal-blue">
                    <p>{{ __('Vul hieronder het aantal in dat je wilt bestellen en klik op doorgaan') }}</p>
                    <div class="row">
                        <div class="col-xs-7">
                            <input
                                id="modalInputQty"
                                type="number"
                                class="form-control"
                                :step="props.orderQuantity"
                                v-model="modalInput"
                                @blur="setQty()" />
                        </div>
                        <div class="col-xs-5">
                            <button
                                @click="showModal = false"
                                id="modalSubmitQty"
                                class="mao-button c-button--black--width-unchanged">
                                {{ __('Doorgaan') }}
                            </button>
                        </div>
                    </div>
                </div>
            </template>
        </modal>
    </div>
</template>

<script setup lang="ts">
    import Modal from './Modal.vue';
    import {onBeforeMount, ref, getCurrentInstance} from 'vue';
    import {siteSettings as siteSettingsStore} from './Store.ts';
    const siteSettings = siteSettingsStore();
    const showModal = ref(false);
    const options = ref([]);
    let __ = (s) => '';
    let props = defineProps({
        orderQuantity: {
            type: Number,
            default: 1,
        },
        startingValue: Number,
    });

    const currentValue = ref<Number | String>(props.orderQuantity);
    const previousValue = ref<Number | String>(props.orderQuantity);
    const modalInput = ref<Number>(props.orderQuantity);

    const emit = defineEmits(['input']);

    const handleInput = () => {
        previousValue.value = currentValue.value;
    };

    const setQty = (pushEmit = true) => {
        if (!options.value.some((x: {value: Number; text: Number | String}) => x.value == modalInput.value))
            options.value.push({value: modalInput.value, text: modalInput.value});
        options.value.sort((x, y) => x.value - y.value);
        currentValue.value = modalInput.value;
        if (pushEmit) emit('input', currentValue);
    };
    function isMultiple() {
        if (currentValue.value == __('meer')) {
            showModal.value = true;
            modalInput.value = Number((currentValue.value = previousValue.value));
        } else {
            emit('input', currentValue);
        }
    }

    onBeforeMount(() => {
        for (let i = 1; i < 11; i++) {
            options.value.push({value: i * props.orderQuantity, text: i * props.orderQuantity});
        }
        if (props.startingValue) {
            modalInput.value = props.startingValue;
            setQty(false);
        }
        __ = getCurrentInstance().appContext.config.globalProperties.__;
    });
</script>

<style scoped></style>
