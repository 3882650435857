<script setup lang="ts">
    import {shoppingCartStore} from './Store.ts';
    import {onMounted} from 'vue';
    const store = shoppingCartStore();

    onMounted(() => {
        store.emptyCart();
        store.emptyCoupon();
    });
</script>

<template></template>

<style scoped></style>
