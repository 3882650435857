<script setup lang="ts">
    import {shoppingCartStore} from './Store.ts';

    const store = shoppingCartStore();
    let props = defineProps({
        longDisplayDeliveryDate: String,
    });
</script>

<template>
    <div
        v-if="store.deliveryDate != ''"
        class="greyInfoBox fs-14"
        id="shippingContainer">
        <i class="fa fa-truck col-green"></i>
        {{ __('De verwachte levertijd voor je bestelling is') }}
        <strong class="col-green">{{ store.deliveryDate }}</strong>
    </div>
</template>

<style scoped></style>
