import CryptoJS from 'crypto-js';

const secretKey = 'your-secret-key'; // Change this to your secret key X3p9LzY2QrWb7NvK

export const encrypt = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};

export const decrypt = (ciphertext) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

export const persistedStateEncrypt = {
    getItem: (key) => {
        const data = localStorage.getItem(key);
        return data ? decrypt(data) : null;
    },
    setItem: (key, value) => {
        localStorage.setItem(key, encrypt(value));
    },
};
