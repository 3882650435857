<template>
    <div class="mao-minicart mao-header-flyout">
        <div class="mao-header-flyout-wrapper">
            <div class="mao-minicart-title">
                {{ __('Winkelwagen') }}
                <span>({{ store.total }})</span>
            </div>

            <div v-if="store.total > 0">
                <div class="mao-notification info">
                    <i class="fa fa-exclamation-triangle"></i>
                    <span id="minicart-info">{{ store.total }}</span>

                    <span id="minicart-info-prod">&nbsp;{{ __('producten') }}</span>
                </div>

                <div class="mao-minicart__items">
                    <template v-for="(item, index) of store.items">
                        <div
                            v-if="index < 3"
                            class="mao-minicart-item">
                            <a :href="item.href">
                                <div class="mao-minicart-item-gfx">
                                    <img
                                        :src="item.shoppingImage"
                                        :alt="item.description + ' ' + item.articleNr" />
                                </div>
                                <div class="mao-minicart-item-name">
                                    {{ item.title }}
                                </div>
                                <div class="mao-minicart-item-price">
                                    <sub>&euro;</sub>
                                    {{ toFixedLocal(item.amount * (item.netPrice * siteSettings.btw)) }}
                                </div>
                            </a>
                        </div>
                    </template>
                </div>

                <dl class="mao-minicart-summary discount">
                    <dt>{{ __('Korting') }}</dt>
                    <dd>&euro;{{ toFixedLocal(store.totalDiscount) }}</dd>
                </dl>

                <dl class="mao-minicart-summary total">
                    <dt>{{ __('Totaal') }}</dt>
                    <dd>
                        <sub>&euro;</sub>
                        {{ toFixedLocal(store.totalPrice) }}
                    </dd>
                </dl>
                <a :href="nextAction">
                    <button class="mao-button yellow full-w">
                        {{ __('Naar de kassa') }}
                        <i class="fa fa-angle-right"></i>
                    </button>
                </a>
                <a :href="shoppingCartRoute">
                    <button class="mao-button black full-w">
                        {{ __('Bekijk je winkelwagen') }}
                        <i class="fa fa-angle-right"></i>
                    </button>
                </a>
            </div>
            <div v-else>
                <div
                    v-if="siteSettings.isChannel == 'cpd'"
                    class="table">
                    <img
                        :src="asset + '/images/empty-cart.png'"
                        class="lazy resize-img-70-percent"
                        alt="" />
                    <p
                        class="mao-custom-h3"
                        style="text-align: center">
                        {{ __('Je winkelwagen is leeg!') }}
                    </p>
                </div>
                <div
                    v-else
                    class="table">
                    <img
                        :src="asset + '/images/CART.png'"
                        class="lazy resize-img-70-percent"
                        alt="" />
                    <p
                        class="mao-custom-h3"
                        style="text-align: center">
                        {{ __('Er zit helemaal niks in je winkelwagen.') }}
                    </p>
                </div>
            </div>
        </div>
        <div class="js__flyout-close flyout-close">
            <span>&times;</span>
        </div>
    </div>
</template>

<script setup>
    import {shoppingCartStore, siteSettings as siteSettingsStore} from './Store.ts';

    const store = shoppingCartStore();
    const siteSettings = siteSettingsStore();
    const props = defineProps({
        nextAction: String,
        shoppingCartRoute: String,
        asset: String,
    });
</script>

<style scoped></style>
